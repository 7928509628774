.message {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 0 8px;
}

.message--user {
  padding-left: 128px;
  align-self: flex-end;
}

.message--assistant {
  padding-right: 128px;
}

.message__avatar-container {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid white;
}

.message__avatar {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.message__content {
  border-radius: 8px;
  background-color: white;
  padding: 15px;
}

.message__loading {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #031B0F;
  animation: pulsate 1.5s infinite ease-in-out;
}

@keyframes pulsate {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

@media screen and (max-width: 600px) {
  .message--user {
    padding-left: 16px;
  }

  .message--assistant {
    padding-right: 16px;
  }
}
