.photos {
  position: fixed;
  bottom: 0;
  height: 580px;
  left: 0;
  right: 0;
  z-index: -1;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.photo {
  max-height: 65vh;
  position: relative;
  flex-shrink: 1;
  min-width: 0;
}

.photo--klopotenko {
  top: 40px;
}

@media screen and (max-width: 1250px) {
  .photo--inna {
    left: 140px;
  }

  .photo--klopotenko {
    left: -110px;
  }
}

@media screen and (max-width: 1000px) {
  .photos {
    align-items: flex-start;
  }

  .photo--inna {
    left: 140px;
    top: 330px;
    transform: scale(0.8);
  }

  .photo--klopotenko {
    left: -100px;
    top: 430px;
  }
}

@media screen and (max-width: 600px) {
  .photo {
    display: none;
  }
}
