.header {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 20px 16px;
}

.header__container {
  display: grid;
  grid-template-columns: 4fr 2fr 4fr;
  align-items: center;
  max-width: 1280px;
  margin: 0 auto;
  padding: 10px 15px;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: rgba(186, 186, 186, 0.447) 0px 0.602187px 0.602187px -1.25px, rgba(186, 186, 186, 0.392) 0px 2.28853px 2.28853px -2.5px, rgba(186, 186, 186, 0.157) 0px 10px 10px -3.75px;
  border-radius: 12px;
  backdrop-filter: blur(15px);
}

.header__links {
  display: flex;
  align-items: center;
  gap: 24px;
}

.header__link {
  text-decoration: none;
  color: black;
  white-space: nowrap;
}

.header__logo-container {
  text-align: center;
}

.header__actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}

@media screen and (max-width: 700px) {
  .header__container {
    grid-template-columns: 1fr 1fr;
  }

  .header__links {
    display: none;
  }

  .header__logo-container {
    text-align: left;
  }
}

@media screen and (max-width: 500px) {
  .header__try {
    display: none;
  }
}
