.chat {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(15px);
  border-radius: 24px;
  border: 2px solid white;
}

.chat-container {
  position: relative;
  max-width: 800px;
  margin: 64px auto 24px;
  min-height: 420px;
}

.chat__header {
  display: flex;
  align-items: center;
  gap: 16px;
  background: rgba(255, 255, 255, 0.5);
  padding: 10px;
  border-radius: 20px;
}

.chat__rest-logo-container {
  flex-shrink: 0;
}

.chat__rest-logo {
  width: 124px;
  height: 100px;
  border-radius: 20px;
  overflow: hidden;
  object-fit: contain;
}

.chat__subtitle1 {
  margin-top: 4px;
  margin-bottom: 16px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
}

.chat__subtitle2 {
  font-size: 28px;
  font-weight: 600;
}

.chat__subtitle3 {
  font-size: 16px;
  line-height: 18px;
  margin-top: 8px;
  opacity: 0.5;
}

.chat__messages {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-right: -8px;
  padding-right: 8px;
  overflow: auto;
}

.chat__actions {
  position: relative;
}

.chat__input {
  width: 100%;
  height: 60px;
  padding-left: 20px;
  padding-right: 180px;
  font-size: 18px;
  border-radius: 16px;
  border: none;
  outline: none;
}

.chat__send {
  position: absolute;
  right: 10px;
  top: 10px;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.leaf {
  position: absolute;
  z-index: -2;
}

.leaf-1 {
  width: 100px;
  right: -70px;
  top: 120px;
  transform: rotate(-20deg);
}

.leaf-2 {
  width: 150px;
  left: -75px;
  top: 65px;
  transform: rotate(45deg);
}

.leaf-3 {
  width: 110px;
  right: -45px;
  bottom: 75px;
  transform: rotate(10deg);
}

.leaf-4 {
  width: 55px;
  right: 220px;
  top: -45px;
  transform: rotate(45deg);
}

.leaf-5 {
  width: 75px;
  left: -30px;
  bottom: 120px;
  transform: rotate(40deg);
}

@media screen and (max-width: 600px) {
  .chat-container {
    margin-top: 24px;
  }

  .chat__subtitle1 {
    font-size: 14px;
  }

  .chat__subtitle2 {
    font-size: 20px;
  }

  .chat__subtitle3 {
    font-size: 12px;
    line-height: 14px;
  }
}

@media screen and (max-width: 480px) {
  .chat-container {
    margin-top: 0;
  }

  .chat__header {
    gap: 8px;
  }

  .chat__send-text {
    display: none;
  }

  .chat__input {
    padding-right: 85px;
  }

  .chat__rest-logo {
    width: 80px;
  }
}
