.language-selector {
  position: relative;
  cursor: pointer;
}

.language-selector__options {
  display: none;
  flex-direction: column;
  gap: 16px;
  position: absolute;
  left: -50px;
  background-color: white;
  padding: 16px;
  border-radius: 8px;
}

.language-selector:hover .language-selector__options {
  display: flex;
}

.language-option {
  display: flex;
  align-items: center;
  gap: 8px;
}

.language-option__flag {
  width: 20px;
}
