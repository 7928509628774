.button {
  display: inline-flex;
  align-items: center;
  padding: 10px 15px;
  gap: 16px;
  border-radius: 8px;
  transition: 400ms all;
  font-size: 18px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
}

.button--contained {
  background-color: rgb(3, 27, 15);
  color: white;
  border: none;
}

.button--outlined {
  background-color: transparent;
  color: rgb(3, 27, 15);
  border: 1px solid rgb(3, 27, 15);
}

.button:hover {
  background-color: rgb(144, 194, 154);
}

.button:active {
  background-color: rgb(203, 239, 212);
}
