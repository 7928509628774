.footer {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 20px;
  text-align: center;
}

.footer__text {
  font-size: 16px;
  font-weight: bold;
}

@media screen and (max-width: 1000px) {
  .footer__text {
    max-width: 300px;
  }
}
