body {
  font-family: Onest, 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #9E7C7B;
  color: #031B0F;
}

* {
  box-sizing: border-box;
}

.markdown-styles {
  font-family: "General Sans", sans-serif;
  text-wrap: initial;
  display: flex;
  flex-direction: column;
  gap: 8px;
  line-height: 18px;
}
.markdown-styles strong {
  font-weight: 600;
}
.markdown-styles ul, .markdown-styles ol {
  padding-left: 0;
  list-style-position: inside;
}
.markdown-styles ul,
.markdown-styles ol {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.markdown-styles ul {
  list-style-type: decimal;
}
.markdown-styles ol {
  list-style-type: disc;
}
.markdown-styles ul > li {
  padding-left: 16px;
  list-style-type: disc !important;
}
.markdown-styles ol > li {
  padding-left: 16px;
  list-style-type: decimal !important;
}
.markdown-styles li:before {
  display: none;
}
.markdown-styles li > p {
  display: inline;
}
.markdown-styles h1 {
  font-size: 18px;
  font-weight: 600;
  margin-top: 16px;
}
.markdown-styles h2, .markdown-styles h3 {
  font-size: 16px;
  font-weight: 600;
  margin-top: 16px;
}
